import * as React from 'react'
import styled from 'styled-components'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import Header from '../components/molecules/header'
import theme from '../components/atoms/theme'
import RegistrationSteps from '../components/organisms/sp-registration-form/registration-steps'

interface Props {
  location: {
    pathname: string
  }
}
const ThankYouContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const BodyHeader = styled.h3`
  max-width: 80%;
  text-align: left;
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    text-align: left;
    max-width: 100%;
    margin-bottom: 3.7rem;
    font-size: 1.9rem;
  }
`

const ThankYou = (props: Props): JSX.Element => {
  return (
    <Layout currentPath={props.location.pathname}>
      <Helmet title={'Thank you for registering'} />
      <Header title={'Thank you for registering'} />
      <ThankYouContainer>
        <BodyHeader>
          Thanks for submitting your registration form to become a Preferred
          Service Provider on the Pacific Business Trust network.
        </BodyHeader>
        <RegistrationSteps checkedSteps={2} />
        <div>
          <p>
            The next step for you will be to complete Cultural Competency
            assessment. The Pacific Business Trust recognises the importance of
            our providers having a Pacific cultural context, along with the
            technical and business advisory skills to effectively engage,
            respond and deliver for the needs of Pasifika businesses.
          </p>
          <p>
            The cultural competency assessment is an online questionnaire that
            will take 30 - 60 Minutes to complete, but you will have 2 weeks to
            complete the assessment. You may need to find documentation or think
            about your responses in the assessment - hence the 2 week timeframe
            to complete the assessment.
          </p>
          <p>
            At the same time, a team within PBT will review your registration
            form and call your referees to assess your technical and business
            advisory skills. Following the cultural competency assessment and
            technical and business advisory checking, we will be back in touch
            with you.
          </p>
          <p>
            In the meantime, if you have any questions please get in touch with
            us.
          </p>
        </div>
      </ThankYouContainer>
    </Layout>
  )
}

export default ThankYou
